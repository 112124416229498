<!-- 门店汇总-按商品 -->

<template>
  <div class="analyse-table store-table-container">
    <div class="flex-btw">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="分类汇总" name="1"></el-tab-pane>
        <el-tab-pane label="商品汇总" name="2"></el-tab-pane>
      </el-tabs>

      <!-- 导出按钮 -->
      <el-tooltip class="item" effect="dark" content="导出" placement="left">
        <span class="border-icon" @click="handleExport">
          <i class="iconfont icon-a-lujing207"></i></span>
      </el-tooltip>
    </div>

    <template v-if="activeName === '1'">
      <el-table :data="classifyTableData" v-loading="loading" tooltip-effect="light" stripe
        :height="'calc(100vh - 350px)'" style="width: 100%" class="summary-table" :border="false"
        :summary-method="getClassifySummaries" show-summary>
        <el-table-column prop="poc_name" label="商品分类名称">
          <template #default="scope">
            <span class="text-black">
              {{ scope.row.poc_name }}
            </span></template>
        </el-table-column>
        <el-table-column label="金额(元)">
          <el-table-column prop="or_allmoney" label="销售金额">
            <template #default="scope">
              <span class="text-grey">
                {{ scope.row.or_allmoney }}
              </span></template></el-table-column>
          <el-table-column prop="or_refund_money" label="退款金额">
            <template #default="scope">
              <span class="text-red">
                {{ scope.row.or_refund_money }}
              </span></template></el-table-column>
          <el-table-column prop="real_money" label="实收金额">
            <template #default="scope">
              <span class="text-theme">
                {{ scope.row.real_money }}
              </span></template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="数量">
          <el-table-column prop="goods_buy_no" label="销售数量">
            <template #default="scope">
              <span class="text-grey">
                {{ scope.row.goods_buy_no }}
              </span></template>
          </el-table-column>
          <el-table-column prop="goods_refund_no" label="退款数量">
            <template #default="scope">
              <span class="text-red">
                {{ scope.row.goods_refund_no }}
              </span></template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="统计周期">
          <el-table-column prop="start" label="开始时间">
            <template #default="scope">
              <span class="text-grey">
                {{ scope.row.start }}
              </span></template></el-table-column>
          <el-table-column prop="end" label="截止时间">
            <template #default="scope">
              <span class="text-grey">
                {{ scope.row.end }}
              </span></template></el-table-column>
        </el-table-column>
      </el-table>
    </template>
    <template v-if="activeName === '2'">
      <el-table :data="summaryTableData" v-loading="loading" tooltip-effect="light" stripe
        :height="'calc(100vh - 350px)'" style="width: 100%" class="summary-table" :border="false"
        :summary-method="getGoodsSummaries" show-summary>
        <el-table-column prop="title" label="商品名称">
          <template #default="scope">
            <span class="text-black">
              {{ scope.row.title }}
            </span></template>
        </el-table-column>
        <el-table-column prop="poc_name" label="商品分类">
          <template #default="scope">
            <span class="text-grey">
              {{ scope.row.poc_name }}
            </span></template> </el-table-column>
        <el-table-column prop="pom_name" label="售卖门店">
          <template #default="scope">
            <span class="text-grey">
              {{ scope.row.pom_name }}
            </span></template> </el-table-column>
        <el-table-column prop="unit" label="单位">
          <template #default="scope">
            <span class="text-grey">
              {{ scope.row.unit }}
            </span></template>
        </el-table-column>
        <el-table-column prop="goods_cost" label="成本">
          <template #default="scope">
            <span class="text-grey">
              {{ scope.row.goods_cost }}
            </span></template>
        </el-table-column>
        <el-table-column label="金额(元)">
          <el-table-column prop="or_allmoney" label="销售金额">
            <template #default="scope">
              <span class="text-grey">
                {{ scope.row.or_allmoney }}
              </span></template></el-table-column>
          <el-table-column prop="or_refund_money" label="退款金额">
            <template #default="scope">
              <span class="text-red">
                {{ scope.row.or_refund_money }}
              </span></template></el-table-column>
          <el-table-column prop="real_money" label="实收金额">
            <template #default="scope">
              <span class="text-theme">
                {{ scope.row.real_money }}
              </span></template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="数量">
          <el-table-column prop="goods_buy_no" label="销售数量">
            <template #default="scope">
              <span class="text-grey">
                {{ scope.row.goods_buy_no }}
              </span></template>
          </el-table-column>

          <el-table-column prop="goods_check_no" label="核销数量">
            <template #default="scope">
              <span class="text-grey">
                {{ scope.row.goods_check_no }}
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="goods_refund_no" label="退款数量">
            <template #default="scope">
              <span class="text-red">
                {{ scope.row.goods_refund_no }}
              </span></template>
          </el-table-column>
          <el-table-column prop="goods_real_no" label="实际数量">
            <template #default="scope">
              <span class="text-theme">
                {{ scope.row.goods_real_no }}
              </span></template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="统计周期">
          <el-table-column prop="start" label="开始时间">
            <template #default="scope">
              <span class="text-grey">
                {{ scope.row.start }}
              </span></template></el-table-column>
          <el-table-column prop="end" label="截止时间">
            <template #default="scope">
              <span class="text-grey">
                {{ scope.row.end }}
              </span></template></el-table-column>
        </el-table-column>
      </el-table>
    </template>
  </div>
</template>

<script>
  import { ref, watch, nextTick } from "vue";
  import { AnalyseApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import { exportExcel, print } from "@/utils/common.js";

  export default {
    props: {
      queryData: {
        type: Object,
        default: async () => { },
      },
    },
    setup(props) {
      const sendParmas = ref({});
      watch(
        () => props.queryData,
        (data) => {
          const lastData = JSON.parse(JSON.stringify(data));
          const parmas = {
            stime: lastData.stime,
            etime: lastData.etime,
            payway: lastData.payway,
            s_id: lastData.scenicId,
            pom_id: lastData.storeId,
          };
          sendParmas.value = parmas;
          nextTick(() => {
            fetchData(activeName.value);
          });
        },
        {
          deep: true,
          immediate: true,
        }
      );

      const classifyTableData = ref([]);
      const summaryTableData = ref([]);
      const statisticsData = ref([]);
      const loading = ref(false);
      function fetchData(type) {
        const lastParmas = JSON.parse(JSON.stringify(sendParmas.value));
        lastParmas.pay_way = lastParmas.payway.length
          ? lastParmas.payway.join(",")
          : "";
        lastParmas.pom_id = lastParmas.pom_id.length
          ? lastParmas.pom_id.join(",")
          : "";

        loading.value = true;
        type === "1" &&
          AnalyseApi.getGoodsClassifySummary(lastParmas).then((res) => {
            loading.value = false;
            if (res.Code === 200) {
              classifyTableData.value = res.Data || [];
              classifyTableData.value.length &&
                classifyTableData.value.forEach((el) => {
                  el.goods_refund_no =
                    el.orr_refund && el.orr_refund.goods_refund_no
                      ? el.orr_refund.goods_refund_no
                      : 0;
                });
            } else {
              let msg = res.Message ? res.Message : "获取数据失败！";
              ElMessage.error(msg);
            }
          });
        type === "2" &&
          AnalyseApi.getGoodsSummary(lastParmas).then((res) => {
            loading.value = false;
            if (res.Code === 200) {
              summaryTableData.value = res.Data || [];
              summaryTableData.value.length &&
                summaryTableData.value.forEach((el) => {
                  el.goods_refund_no =
                    el.orr_refund && el.orr_refund.goods_refund_no
                      ? el.orr_refund.goods_refund_no
                      : 0;
                });
            } else {
              let msg = res.Message ? res.Message : "获取数据失败！";
              ElMessage.error(msg);
            }
          });
      }
      function getTime(data, type) {
        // type 1最小 2最大
        let newArr = data.map((ele) => {
          return new Date(ele).getTime();
        });
        let minIndex = 0;
        let minDate = newArr[0];
        let maxIndex = 0;
        let maxDate = newArr[0];
        newArr.forEach((ele, index) => {
          if (ele < minDate) {
            minDate = ele;
            minIndex = index;
          }
          if (ele > maxDate) {
            maxDate = ele;
            maxIndex = index;
          }
        });

        return data[type === 1 ? minIndex : maxIndex];
      }
      const getClassifySummaries = (param) => {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "合计";
            return;
          } else if (index === 6) {
            const timeData = data.map((item) => item[column.property]);
            sums[index] = getTime(timeData, 1);
          } else if (index === 7) {
            const timeData = data.map((item) => item[column.property]);
            sums[index] = getTime(timeData, 2);
          } else {
            const values = data.map((item) => Number(item[column.property]));
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return roundNumber(prev + curr);
                } else {
                  return roundNumber(prev);
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "-";
            }
          }
        });

        return sums;
      };
      const getGoodsSummaries = (param) => {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "合计";
            return;
          } else if (index === 1 || index === 2 || index === 3) {
            sums[index] = " ";
          } else if (index === 12) {
            const timeData = data.map((item) => item[column.property]);
            sums[index] = getTime(timeData, 1);
          } else if (index === 13) {
            const timeData = data.map((item) => item[column.property]);
            sums[index] = getTime(timeData, 2);
          } else {
            const values = data.map((item) => Number(item[column.property]));
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return roundNumber(prev + curr);
                } else {
                  return roundNumber(prev);
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "N/A";
            }
          }
        });

        return sums;
      };
      function roundNumber(num) {
        return Math.round(+num * 100) / 100;
      }
      const activeName = ref("1");
      function handleClick(tab) {
        fetchData(tab.props.name);
      }
      const classifyPropertiesConfig = ref([
        { field: "poc_name", displayName: "商品分类名称" },
        { field: "or_allmoney", displayName: "金额(元)-销售金额" },
        { field: "or_refund_money", displayName: "金额(元)-退款金额" },
        { field: "real_money", displayName: "金额(元)-实收金额" },
        { field: "goods_buy_no", displayName: "数量-销售数量" },
        { field: "goods_refund_no", displayName: "数量-退款数量" },
        { field: "start", displayName: "统计周期-开始时间" },
        { field: "end", displayName: "统计周期-截止时间" },
      ]);
      const summaryPropertiesConfig = ref([
        { field: "title", displayName: "商品名称" },
        { field: "poc_name", displayName: "商品分类" },
        { field: "pom_name", displayName: "售卖门店" },
        { field: "unit", displayName: "单位" },
        { field: "goods_cost", displayName: "成本" },
        { field: "or_allmoney", displayName: "金额(元)-销售金额" },
        { field: "or_refund_money", displayName: "金额(元)-退款金额" },
        { field: "real_money", displayName: "金额(元)-实收金额" },
        { field: "goods_buy_no", displayName: "数量-销售数量" },
        { field: "goods_check_no", displayName: "数量-核销数量" },
        { field: "goods_refund_no", displayName: "数量-退款数量" },
        { field: "goods_real_no", displayName: "数量-实际数量" },
        { field: "start", displayName: "统计周期-开始时间" },
        { field: "end", displayName: "统计周期-截止时间" },
      ]);

      function processData(data) {
        data.length &&
          data.forEach((el) => {
            el.typeText = ["", "收入", "支出"][el.order_type];
          });
        return data;
      }
      function getLastData() {
        let title = "";
        let data = null;
        let config = null;
        if (activeName.value === "1") {
          title = "分类汇总";
          data = classifyTableData.value;
          config = classifyPropertiesConfig.value;
        } else if (activeName.value === "2") {
          title = "商品汇总";
          data = summaryTableData.value;
          config = summaryPropertiesConfig.value;
        }
        return [title, data, config];
      }
      function handlePrint() {
        print(getLastData()[0], processData(getLastData()[1]), getLastData()[2]);
      }
      function handleExport() {
        try {
          exportExcel(
            getLastData()[0],
            processData(getLastData()[1]),
            getLastData()[2]
          );
          ElMessage.success({
            message: "导出成功！",
          });
        } catch (e) {
          ElMessage.error({
            message: "导出失败！",
          });
        }
      }
      return {
        loading,
        handleExport,
        handlePrint,
        statisticsData,
        roundNumber,
        activeName,
        handleClick,
        classifyTableData,
        summaryTableData,
        getClassifySummaries,
        getGoodsSummaries,
      };
    },
  };
</script>

<style lang="scss">
</style>