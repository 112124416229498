<template>
  <div class="main-cnt statement-container store-container">
    <div class="flex-btw">
      <div class="flex">
        <el-select v-model="formData.scenicId" placeholder="请选择园区">
          <el-option :label="item.s_name" :value="item.s_id" v-for="item in scenicOptions" :key="item.s_id"></el-option>
        </el-select>

        <div class="flex" style="line-height: 40px;">
          <div class="s-m-l-r flex-shrink" style="color:var(--top-text-color);">支付时间</div>
          <el-date-picker v-model="formData.paymentTime" type="daterange" :clearable="false" placeholder="请选择支付时间"
            value-format="YYYY-MM-DD" range-separator="-" start-placeholder="支付时间开始" end-placeholder="支付时间结束"
            @change="paymentTimeChange">
          </el-date-picker>
        </div>

        <el-select class="s-m-l-r" v-if="isTableDetail" v-model="queryData.storeId" multiple collapse-tags clearable
          placeholder="请选择门店">
          <el-option :label="item.pom_name" :value="item.pom_id" v-for="item in storeOptions" :key="item.pom_id">
          </el-option>
        </el-select>

        <el-select class="s-m-l-r" v-if="isTableDetail" v-model="queryData.payway" multiple clearable collapse-tags
          placeholder="请选择支付方式">
          <el-option :label="item.orp_name" :value="item.orp_id" v-for="item in orderTypeOptions" :key="item.orp_id">
          </el-option>
        </el-select>

        <el-button class="s-m-l-r" type="primary" plain round @click="handleReset">重置</el-button>
      </div>

      <div>
        <el-button v-if="showModule !== 1" type="primary" round @click="changeModule(1)">统计</el-button>
        <el-button v-if="showModule === 1" @click="changeModule(2)" type="primary" round>按门店</el-button>
        <el-button v-if="showModule === 1" @click="changeModule(3)" type="primary" round>按商品</el-button>
      </div>
    </div>
    <!-- <el-row :gutter="10">
      <el-col :span="2">
        <el-select v-model="formData.scenicId" placeholder="请选择园区">
          <el-option :label="item.s_name" :value="item.s_id" v-for="item in scenicOptions" :key="item.s_id"></el-option>
        </el-select>
      </el-col>

      <el-col :span="6">
        <el-row>
          <el-col :span="5" class="text-center">
            <span>支付时间</span>
          </el-col>
          <el-col :span="9">
            <el-date-picker
              :clearable="false"
              v-model="formData.stime"
              type="date"
              placeholder="支付时间开始"
              @change="changeStime"
            />
          </el-col>
          <el-col :span="1" class="text-center">
            <span>-</span>
          </el-col>
          <el-col :span="9">
            <el-date-picker
              :clearable="false"
              v-model="formData.etime"
              type="date"
              placeholder="支付时间结束"
              @change="changeEtime"
            />
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4">
        <el-select
          v-if="isTableDetail"
          v-model="queryData.storeId"
          multiple
          collapse-tags
          clearable
          placeholder="请选择门店"
        >
          <el-option
            :label="item.pom_name"
            :value="item.pom_id"
            v-for="item in storeOptions"
            :key="item.pom_id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          v-if="isTableDetail"
          v-model="queryData.payway"
          multiple
          clearable
          collapse-tags
          placeholder="请选择支付方式"
        >
          <el-option
            :label="item.orp_name"
            :value="item.orp_id"
            v-for="item in orderTypeOptions"
            :key="item.orp_id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4"></el-col>
      <el-col :span="5" class="action-btn">
        <template v-if="showModule !== 1">
          <el-tooltip
            class="item"
            effect="dark"
            content="导出"
            placement="left"
          >
            <span class="border-icon" @click="handleExport">
              <i class="iconfont icon-a-lujing207"></i
            ></span>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="打印"
            placement="left"
          >
            <span class="border-icon mr-10" @click="handlePrint">
              <i class="iconfont icon-a-lujing206"></i
            ></span>
          </el-tooltip>
          <el-button @click="handleReset">重置</el-button>
        </template>
        <el-button
          v-if="showModule !== 1"
          type="primary"
          @click="changeModule(1)"
          >统计</el-button
        >

        <el-button
          v-if="showModule === 1"
          @click="changeModule(2)"
          class="w-120 store-query"
          >按门店查询</el-button
        >
        <el-button
          v-if="showModule === 1"
          type="primary"
          @click="changeModule(3)"
          class="w-120"
          >按商品查询</el-button
        >
      </el-col>
    </el-row> -->

    <div class="content">
      <!-- 统计表 -->
      <StoreEcharts v-if="showModule === 1" :queryData="lastEchartQuery" @paywayClick="paywayClick"
        @storeClick="storeClick"></StoreEcharts>

      <!-- 按门店 -->
      <StoreTable v-if="showModule === 2" ref="scenicTableRef" :queryData="lastTableQuery" @showSelect="showSelect"
        @clearFilter="clearFilter" :echartClick="echartClick"></StoreTable>

      <!-- 按商品 -->
      <StoreGoodsTable v-if="showModule === 3" ref="scenicTableRef" :queryData="lastTableQuery"></StoreGoodsTable>
    </div>
  </div>
</template>
<script>
  import { ref, onMounted, reactive, computed } from "vue";
  import { BasicApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import StoreEcharts from "../components/StoreEcharts.vue";
  import StoreTable from "../components/StoreTable.vue";
  import StoreGoodsTable from "../components/StoreGoodsTable.vue";

  import dayjs from "dayjs";
  export default {
    components: {
      StoreEcharts,
      StoreTable,
      StoreGoodsTable,
    },
    setup() {
      const scenicOptions = ref([]);  // 园区选项
      /**
       * 
       * 获取园区列表数据
       * 
       * **/
      const getAllScenicData = () => {
        BasicApi.getAllScenicData({ auth: '1' }).then((res) => {
          if (res.Code === 200) {
            scenicOptions.value = res.Data ? res.Data : [];
            formData.scenicId = res.Data ? res.Data[0].s_id : '';
          } else {
            let msg = res.Message ? res.Message : "获取园区数据失败！";
            ElMessage.error(msg);
          }
        });
      }
      /**
       * 
       * 支付时间选择
       * 
       * **/
      const paymentTimeChange = () => {
        formData.stime = formData.paymentTime[0];
        formData.etime = formData.paymentTime[1];
      }
      /**
       * 
       * 重置按钮
       * 
       * **/
      function handleReset() {
        formData.scenicId = scenicOptions.value[0].s_id;
        formData.paymentTime = [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")];
        queryData.value = {
          storeId: [],
          payway: [],
        };
      }
      /**
       * 
       * 清空门店、支付方式选择
       * 
       * **/
      const clearFilter = () => {
        queryData.value = {
          storeId: [],
          payway: [],
        };
      }


      onMounted(() => {
        getOrderType();
        getAllScenicData();
        getStoreData();
      });
      const showModule = ref(1); //1图 2按门店查询表格 3按商品查询表格
      const isTableDetail = ref(false);
      function showSelect(flag) {
        isTableDetail.value = flag;
      }
      const formData = reactive({
        scenicId: "",
        paymentTime: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
        stime: '',
        etime: '',
      });
      const queryData = ref({
        storeId: [],
        payway: [],
      });
      const orderTypeOptions = ref([]);
      const getOrderType = () => {
        BasicApi.paywayOptions().then((res) => {
          if (res.Code === 200) {
            orderTypeOptions.value = res.Data;
          } else {
            let msg = res.Message ? res.Message : "获取支付方式失败！";
            ElMessage.error(msg);
          }
        });
      };

      const storeOptions = ref([]);
      function getStoreData() {
        BasicApi.getAllPolyMerchantData().then((res) => {
          if (res.Code === 200) {
            if (res.Data.length) {
              storeOptions.value = res.Data;
            }
          } else {
            let msg = res.Message ? res.Message : "获取门店数据失败！";
            ElMessage.error(msg);
          }
        });
      }

      function changeStime(data) {
        if (formData["etime"] && dayjs(formData["etime"]).isBefore(dayjs(data))) {
          //如果结束时间有值 且 在开始时间之前
          formData["stime"] = formData["etime"];
          formData["etime"] = data;
        } else {
          formData["stime"] = data;
        }
      }
      function changeEtime(data) {
        if (formData["stime"] && dayjs(formData["stime"]).isAfter(dayjs(data))) {
          formData["etime"] = formData["stime"];
          formData["stime"] = data;
        } else {
          formData["etime"] = data;
        }
      }
      function changeModule(type) {
        showSelect(false);
        echartClick.value = false;
        showModule.value = type;
        if (showModule.value === 1) {
          queryData.value = {
            storeId: [],
            payway: [],
          };
        }
      }


      // 是否点击图表
      const echartClick = ref(false);
      function paywayClick(data) {
        echartClick.value = true;
        queryData.value.payway = [data];
        queryData.value.storeId = [];
        showModule.value = 2;
      }
      function storeClick(data) {
        echartClick.value = true;
        queryData.value.storeId = [data];
        queryData.value.payway = [];
        showModule.value = 2;
      }
      const lastTableQuery = computed(() => {
        return {
          ...queryData.value,
          stime: formData.paymentTime[0],
          etime: formData.paymentTime[1],
          scenicId: formData.scenicId,
        };
      });
      const lastEchartQuery = computed(() => {
        return {
          stime: formData.paymentTime[0],
          etime: formData.paymentTime[1],
          scenicId: formData.scenicId,
        };
      });
      const scenicTableRef = ref(null);
      function handleExport() {
        scenicTableRef.value.handleExport();
      }
      function handlePrint() {
        scenicTableRef.value.handlePrint();
      }
      return {
        getAllScenicData,
        scenicOptions,
        paymentTimeChange,
        clearFilter,

        changeModule,
        formData,
        changeEtime,
        changeStime,
        queryData,
        orderTypeOptions,
        paywayClick,
        storeClick,
        handleReset,
        lastTableQuery,
        showModule,
        handleExport,
        handlePrint,
        scenicTableRef,
        lastEchartQuery,
        storeOptions,
        showSelect,
        echartClick,
        isTableDetail,
      };
    },
  };
</script>

<style lang="scss">
  .main-cnt {
    height: auto !important;
  }

  .store-container {
    .w-120 {
      width: 100px !important;
    }

    .store-query {
      background-color: #60cbba !important;
      border-color: #60cbba;
      color: var(--text-white-color) !important;
    }
  }
</style>