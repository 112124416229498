<template>
  <div class="analyse-echarts">
    <div class="top-data">
      <div class="basic-data" v-if="statisticsData && statisticsData.total">
        <div class="basic-item">
          <img src="@/assets/img/money.png" />
          <div>
            <span>{{ roundNumber(statisticsData?.total.pay_money) }}</span>
            <p>销售金额（元）</p>
          </div>
        </div>
        <div class="basic-item">
          <img src="@/assets/img/number.png" />
          <div>
            <span>{{
              +statisticsData?.total.refund_num + +statisticsData?.total.pay_num
            }}</span>
            <p>订单总数（笔）</p>
          </div>
        </div>
        <div class="basic-item">
          <img src="@/assets/img/money.png" />
          <div>
            <span>{{ roundNumber(statisticsData?.total.refund_money) }}</span>
            <p>退款金额（元）</p>
          </div>
        </div>
        <div class="basic-item">
          <img src="@/assets/img/number.png" />
          <div>
            <span>{{ statisticsData?.total.refund_num }}</span>
            <p>退款笔数（笔）</p>
          </div>
        </div>
        <div class="basic-item">
          <img src="@/assets/img/money.png" />
          <div>
            <span>{{
              roundNumber(
                roundNumber(statisticsData?.total.pay_money) -
                  roundNumber(statisticsData?.total.refund_money)
              )
            }}</span>
            <p>实收金额（元）</p>
          </div>
        </div>
        <div class="basic-item">
          <img src="@/assets/img/number.png" />
          <div>
            <span>{{ statisticsData?.total.pay_num }}</span>
            <p>实际订单（笔）</p>
          </div>
        </div>
      </div>
    </div>
    <div class="payway-data">
      <div class="statistics-title">销售方式营业统计</div>
      <div class="payway-flex">
        <div ref="paywayMoneyChartRef" :style="{ height: '400px', width: '49%' }"></div>
        <div ref="paywayNumberChartRef" :style="{ height: '400px', width: '49%' }"></div>
      </div>
    </div>
    <div class="payway-data mt-50">
      <div class="statistics-title">门店营业统计</div>
      <div class="payway-flex">
        <div ref="storeMoneyChartRef" :style="{ height: '600px', width: '49%' }"></div>
        <div ref="storeNumberChartRef" :style="{ height: '600px', width: '49%' }"></div>
      </div>
    </div>
    <!-- <div class="payway-data mt-50">
      <div class="statistics-title">分类营业统计</div>
      <div class="payway-flex">
        <div
          ref="classifyMoneyChartRef"
          :style="{ height: '600px', width: '49%' }"
        ></div>
        <div
          ref="classifyNumberChartRef"
          :style="{ height: '600px', width: '49%' }"
        ></div>
      </div>
    </div> -->
  </div>
</template>
<script>
  import { ref, onMounted, watch, nextTick } from "vue";
  import * as echarts from "echarts";
  import { AnalyseApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";

  export default {
    props: {
      queryData: {
        type: Object,
        default: async () => { },
      },
    },
    setup(props, { emit }) {
      const statisticsData = ref({});
      onMounted(() => { });
      watch(
        () => props.queryData,
        (data) => {
          if (data) {
            const lastData = JSON.parse(JSON.stringify(data));
            const parmas = {
              search_type: 1,
              s_id: lastData.scenicId,
              stime: lastData.stime,
              etime: lastData.etime,
            };
            if (lastData.scenicId && lastData.stime && lastData.etime) {
              AnalyseApi.getStoreSummary(parmas).then((res) => {
                if (res.Code === 200) {
                  statisticsData.value = res.Data;
                  nextTick(() => {
                    paywayMoneyEchart();
                    paywayNumberEchart();
                    storeMoneyEchart();
                    storeNumberEchart();
                    classifyNumberEchart();
                    classifyMoneyEchart();
                  });
                } else {
                  let msg = res.Message ? res.Message : "获取数据失败！";
                  ElMessage.error(msg);
                }
              });
            }
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
      function roundNumber(num) {
        return Math.round(+num * 100) / 100;
      }
      const paywayMoneyChartRef = ref(null);
      function paywayMoneyEchart() {
        if (paywayMoneyChartRef.value) {
          const paywayMoneyChart = echarts.init(paywayMoneyChartRef.value);
          window.addEventListener("resize", function () {
            paywayMoneyChart.resize();
          });
          const xData = [];
          const payMoney = [];
          const refundMoney = [];
          const allMoney = [];
          let seriesStyle = {
            type: "bar",
            barWidth: 12,
            label: {
              show: true,
              position: "top",
            },
            itemStyle: {
              borderRadius: [8, 8, 0, 0],
            },
          };
          statisticsData.value &&
            statisticsData.value.payway &&
            statisticsData.value.payway.length &&
            statisticsData.value.payway.forEach((el) => {
              seriesStyle = {
                ...seriesStyle,
                paywayId: el.id,
              };
              xData.push(el.name);
              payMoney.push(roundNumber(el.pay_money));
              refundMoney.push(roundNumber(el.refund_money));
              allMoney.push(
                roundNumber(
                  roundNumber(el.pay_money) - roundNumber(el.refund_money)
                )
              );
            });
          const option = {
            tooltip: {},
            legend: {
              itemWidth: 32,
              itemHeight: 14,
              itemGap: 30,
              textStyle: {
                color: "#666",
                fontSize: 14,
                align: "left",
              },
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "1%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data: xData,
              axisLine: {
                show: false,
                // lineStyle: {
                //   color: "#ccc",
                // },
              },
              axisLabel: {
                show: true,
                color: "#1A1A1A",
                fontSize: 16,
              },
              axisTick: {
                show: false,
              },
            },
            yAxis: {
              type: "value",
              boundaryGap: [0, 0.01],
              splitNumber: 6,
              // interval: 1000,
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "#CCCCCC",
                },
              },
            },
            series: [
              {
                name: "销售金额",
                data: payMoney,
                ...seriesStyle,
                color: "#0073FF",
                label: {
                  show: true,
                  precision: 1,
                  position: "top",
                  rotate: -20,
                  offset: [2, 0],
                  textStyle: {
                    color: "#0073FF",
                    fontSize: 10,
                  },
                },
              },
              {
                name: "实收金额",
                data: allMoney,
                ...seriesStyle,
                color: "#10BB82",
                label: {
                  show: true,
                  precision: 1,
                  position: "top",
                  rotate: -20,
                  offset: [2, 0],
                  textStyle: {
                    color: "#10BB82",
                    fontSize: 10,
                  },
                },
              },
              {
                name: "退款金额",
                data: refundMoney,
                ...seriesStyle,
                color: "#FFB045",
                label: {
                  show: true,
                  precision: 1,
                  position: "top",
                  offset: [2, 0],
                  rotate: -20,
                  textStyle: {
                    color: "#FFB045",
                    fontSize: 10,
                  },
                },
              },
            ],
          };
          paywayMoneyChart.setOption(option);
          paywayMoneyChart.on("click", (param) => {
            if (statisticsData.value.payway.length) {
              const item = statisticsData.value.payway.find(
                (el) => el.name === param.name
              );
              emit("paywayClick", item.id);
            }
          });
        }
      }
      const paywayNumberChartRef = ref(null);
      function paywayNumberEchart() {
        if (paywayNumberChartRef.value) {
          const paywayNumberChart = echarts.init(paywayNumberChartRef.value);
          window.addEventListener("resize", function () {
            paywayNumberChart.resize();
          });
          const xData = [];
          const payNumber = [];
          const refundNumber = [];
          const allNumber = [];
          const seriesStyle = {
            type: "bar",
            barWidth: 12,
            itemStyle: {
              borderRadius: [8, 8, 0, 0],
            },
          };
          statisticsData.value &&
            statisticsData.value.payway &&
            statisticsData.value.payway.length &&
            statisticsData.value.payway.forEach((el) => {
              xData.push(el.name);
              payNumber.push(el.pay_num);
              refundNumber.push(el.refund_num);
              allNumber.push(el.pay_num + el.refund_num);
            });
          const option = {
            tooltip: {},
            legend: {
              itemWidth: 32,
              itemHeight: 14,
              itemGap: 30,
              textStyle: {
                color: "#666",
                fontSize: 14,
                align: "left",
              },
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data: xData,
              axisLine: {
                show: false,
                // lineStyle: {
                //   color: "#ccc",
                // },
              },
              axisLabel: {
                show: true,
                color: "#1A1A1A",
                fontSize: 16,
              },
              axisTick: {
                show: false,
              },
            },
            yAxis: {
              type: "value",
              boundaryGap: [0, 0.01],
              splitNumber: 6,
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "#CCCCCC",
                },
              },
            },
            series: [
              {
                name: "订单总数",
                data: allNumber,
                ...seriesStyle,
                color: "#0073FF ",
                label: {
                  show: true,
                  precision: 1,
                  position: "top",
                  rotate: -20,
                  offset: [2, 0],
                  textStyle: {
                    color: "#0073FF",
                    fontSize: 10,
                  },
                },
              },
              {
                name: "实际笔数",
                data: payNumber,
                ...seriesStyle,
                color: "#10BB82",
                label: {
                  show: true,
                  precision: 1,
                  position: "top",
                  rotate: -20,
                  offset: [2, 0],
                  textStyle: {
                    color: "#10BB82",
                    fontSize: 10,
                  },
                },
              },
              {
                name: "退款笔数",
                data: refundNumber,
                ...seriesStyle,
                color: "#FFB045",
                label: {
                  show: true,
                  precision: 1,
                  position: "top",
                  rotate: -20,
                  offset: [2, 0],
                  textStyle: {
                    color: "#FFB045",
                    fontSize: 10,
                  },
                },
              },
            ],
          };
          paywayNumberChart.setOption(option);
          paywayNumberChart.on("click", (param) => {
            if (statisticsData.value.payway.length) {
              const item = statisticsData.value.payway.find(
                (el) => el.name === param.name
              );
              emit("paywayClick", item.id);
            }
          });
        }
      }

      const storeMoneyChartRef = ref(null);
      function storeMoneyEchart() {
        if (storeMoneyChartRef.value) {
          const storeMoneyChart = echarts.init(storeMoneyChartRef.value);
          window.addEventListener("resize", function () {
            storeMoneyChart.resize();
          });
          const xData = [];
          const payMoney = [];
          const refundMoney = [];
          const allMoney = [];
          const seriesStyle = {
            type: "bar",
            barWidth: 12,
            itemStyle: {
              borderRadius: [0, 8, 8, 0],
            },
          };
          statisticsData.value &&
            statisticsData.value.polymerization_merchant &&
            statisticsData.value.polymerization_merchant.length &&
            statisticsData.value.polymerization_merchant.forEach((el) => {
              xData.push(el.name);
              payMoney.push(roundNumber(el.pay_money));
              refundMoney.push(roundNumber(el.refund_money));
              allMoney.push(
                roundNumber(
                  roundNumber(el.pay_money) - roundNumber(el.refund_money)
                )
              );
            });
          const option = {
            tooltip: {},
            legend: {
              itemWidth: 32,
              itemHeight: 14,
              itemGap: 30,
              textStyle: {
                color: "#666",
                fontSize: 14,
                align: "left",
              },
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "value",
              data: xData,
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "#ccc",
                },
              },
              axisTick: {
                show: false,
              },
            },
            yAxis: {
              type: "category",
              data: xData,
              axisTick: {
                show: false,
              },
              axisLabel: {
                color: "#1A1A1A",
                fontSize: "14",
                interval: 0,
                // align: "left",
                formatter: function (params) {
                  var newParamsName = ""; // 最终拼接成的字符串
                  var paramsNameNumber = params.length; // 实际标签的个数
                  var provideNumber = 12; // 每行能显示的字的个数
                  var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
                  /**
                   * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                   */
                  // 条件等同于rowNumber>1
                  if (paramsNameNumber > provideNumber) {
                    /** 循环每一行,p表示行 */
                    for (var p = 0; p < rowNumber; p++) {
                      var tempStr = ""; // 表示每一次截取的字符串
                      var start = p * provideNumber; // 开始截取的位置
                      var end = start + provideNumber; // 结束截取的位置
                      // 此处特殊处理最后一行的索引值
                      if (p == rowNumber - 1) {
                        // 最后一次不换行
                        tempStr = params.substring(start, paramsNameNumber);
                      } else {
                        // 每一次拼接字符串并换行
                        tempStr = params.substring(start, end) + "\n";
                      }
                      newParamsName += tempStr; // 最终拼成的字符串
                    }
                  } else {
                    // 将旧标签的值赋给新标签
                    newParamsName = params;
                  }
                  //将最终的字符串返回
                  return newParamsName;
                },
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#1A1A1A",
                },
              },
            },
            series: [
              {
                name: "销售金额",
                data: payMoney,
                ...seriesStyle,
                color: "#0073FF",
                label: {
                  show: true,
                  precision: 1,
                  position: "right",
                  textStyle: {
                    color: "#0073FF ",
                  },
                },
              },
              {
                name: "实收金额",
                data: allMoney,
                ...seriesStyle,
                color: "#10BB82",
                label: {
                  show: true,
                  precision: 1,
                  position: "right",
                  textStyle: {
                    color: "#10BB82 ",
                  },
                },
              },
              {
                name: "退款金额",
                data: refundMoney,
                ...seriesStyle,
                color: "#FFB045",
                label: {
                  show: true,
                  precision: 1,
                  position: "right",
                  textStyle: {
                    color: "#FFB045",
                  },
                },
              },
            ],
          };
          storeMoneyChart.setOption(option);
          storeMoneyChart.on("click", (param) => {
            if (statisticsData.value.polymerization_merchant.length) {
              const item = statisticsData.value.polymerization_merchant.find(
                (el) => el.name === param.name
              );
              const lastValue = item.id;
              emit("storeClick", lastValue);
            }
          });
          if (
            statisticsData.value &&
            statisticsData.value.polymerization_merchant &&
            statisticsData.value.polymerization_merchant.length
          ) {
            const autoHeight = option.yAxis.data.length * 100 + 100;
            storeMoneyChart.getDom().style.height = autoHeight + "px";
            storeMoneyChart.getDom().childNodes[0].style.height =
              autoHeight + "px";
            storeMoneyChart
              .getDom()
              .childNodes[0].childNodes[0].setAttribute("height", autoHeight);
            storeMoneyChart.getDom().childNodes[0].childNodes[0].style.height =
              autoHeight + "px";
            storeMoneyChart.resize();
          }
        }
      }
      const storeNumberChartRef = ref(null);
      function storeNumberEchart() {
        if (storeNumberChartRef.value) {
          const storeNumberChart = echarts.init(storeNumberChartRef.value);
          window.addEventListener("resize", function () {
            storeNumberChart.resize();
          });
          const xData = [];
          const payNumber = [];
          const refundNumber = [];
          const allNumber = [];
          const seriesStyle = {
            type: "bar",
            barWidth: 12,
            itemStyle: {
              borderRadius: [0, 8, 8, 0],
            },
          };

          statisticsData.value &&
            statisticsData.value.polymerization_merchant &&
            statisticsData.value.polymerization_merchant.length &&
            statisticsData.value.polymerization_merchant.forEach((el) => {
              xData.push(el.name);
              payNumber.push(el.pay_num);
              refundNumber.push(el.refund_num);
              allNumber.push(el.pay_num + el.refund_num);
            });
          const option = {
            tooltip: {},
            legend: {
              itemWidth: 32,
              itemHeight: 14,
              itemGap: 30,
              textStyle: {
                color: "#666",
                fontSize: 14,
                align: "left",
              },
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "value",
              data: xData,
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "#ccc",
                },
              },
              axisTick: {
                show: false,
              },
            },
            yAxis: {
              type: "category",
              data: xData,
              axisTick: {
                show: false,
              },
              axisLabel: {
                color: "#1A1A1A",
                fontSize: "14",
                interval: 0,
                // align: "left",
                formatter: function (params) {
                  var newParamsName = ""; // 最终拼接成的字符串
                  var paramsNameNumber = params.length; // 实际标签的个数
                  var provideNumber = 12; // 每行能显示的字的个数
                  var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
                  /**
                   * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                   */
                  // 条件等同于rowNumber>1
                  if (paramsNameNumber > provideNumber) {
                    /** 循环每一行,p表示行 */
                    for (var p = 0; p < rowNumber; p++) {
                      var tempStr = ""; // 表示每一次截取的字符串
                      var start = p * provideNumber; // 开始截取的位置
                      var end = start + provideNumber; // 结束截取的位置
                      // 此处特殊处理最后一行的索引值
                      if (p == rowNumber - 1) {
                        // 最后一次不换行
                        tempStr = params.substring(start, paramsNameNumber);
                      } else {
                        // 每一次拼接字符串并换行
                        tempStr = params.substring(start, end) + "\n";
                      }
                      newParamsName += tempStr; // 最终拼成的字符串
                    }
                  } else {
                    // 将旧标签的值赋给新标签
                    newParamsName = params;
                  }
                  //将最终的字符串返回
                  return newParamsName;
                },
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#1A1A1A",
                },
              },
            },
            series: [
              {
                name: "订单总数",
                data: allNumber,
                ...seriesStyle,
                color: "#0073FF",
                label: {
                  show: true,
                  precision: 1,
                  position: "right",
                  textStyle: {
                    color: "#0073FF",
                  },
                },
              },
              {
                name: "实际笔数",
                data: payNumber,
                ...seriesStyle,
                color: "#10BB82",
                label: {
                  show: true,
                  precision: 1,
                  position: "right",
                  textStyle: {
                    color: "#10BB82",
                  },
                },
              },
              {
                name: "退款笔数",
                data: refundNumber,
                ...seriesStyle,
                color: "#FFB045",
                label: {
                  show: true,
                  precision: 1,
                  position: "right",
                  textStyle: {
                    color: "#FFB045",
                  },
                },
              },
            ],
          };
          storeNumberChart.setOption(option);
          storeNumberChart.on("click", (param) => {
            if (statisticsData.value.polymerization_merchant.length) {
              const item = statisticsData.value.polymerization_merchant.find(
                (el) => el.name === param.name
              );
              const lastValue = item.id;
              emit("storeClick", lastValue);
            }
          });
          if (
            statisticsData.value &&
            statisticsData.value.polymerization_merchant &&
            statisticsData.value.polymerization_merchant.length
          ) {
            const autoHeight = option.yAxis.data.length * 100 + 100;
            storeNumberChart.getDom().style.height = autoHeight + "px";
            storeNumberChart.getDom().childNodes[0].style.height =
              autoHeight + "px";
            storeNumberChart
              .getDom()
              .childNodes[0].childNodes[0].setAttribute("height", autoHeight);
            storeNumberChart.getDom().childNodes[0].childNodes[0].style.height =
              autoHeight + "px";
            storeNumberChart.resize();
          }
        }
      }
      const classifyMoneyChartRef = ref(null);
      function classifyMoneyEchart() {
        if (classifyMoneyChartRef.value) {
          const classifyMoneyChart = echarts.init(classifyMoneyChartRef.value);
          window.addEventListener("resize", function () {
            classifyMoneyChart.resize();
          });
          const xData = [];
          const payMoney = [];
          const refundMoney = [];
          const allMoney = [];
          const seriesStyle = {
            type: "bar",
            barWidth: 20,
            itemStyle: {
              borderRadius: [0, 8, 8, 0],
            },
          };
          statisticsData.value &&
            statisticsData.value.goods_classes &&
            statisticsData.value.goods_classes.length &&
            statisticsData.value.goods_classes.forEach((el) => {
              xData.push(el.poc_name);
              payMoney.push(roundNumber(el.or_allmoney));
              refundMoney.push(roundNumber(el.or_refund_money));
              allMoney.push(
                roundNumber(
                  roundNumber(el.or_allmoney) - roundNumber(el.or_refund_money)
                )
              );
            });
          const option = {
            tooltip: {},
            legend: {
              itemWidth: 32,
              itemHeight: 14,
              itemGap: 30,
              textStyle: {
                color: "#666",
                fontSize: 14,
                align: "left",
              },
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "value",
              data: xData,
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "#ccc",
                },
              },
              axisTick: {
                show: false,
              },
            },
            yAxis: {
              type: "category",
              data: xData,
              axisTick: {
                show: false,
              },
              axisLabel: {
                color: "#1A1A1A",
                fontSize: "14",
                interval: 0,
                // align: "left",
                formatter: function (params) {
                  var newParamsName = ""; // 最终拼接成的字符串
                  var paramsNameNumber = params.length; // 实际标签的个数
                  var provideNumber = 12; // 每行能显示的字的个数
                  var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
                  /**
                   * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                   */
                  // 条件等同于rowNumber>1
                  if (paramsNameNumber > provideNumber) {
                    /** 循环每一行,p表示行 */
                    for (var p = 0; p < rowNumber; p++) {
                      var tempStr = ""; // 表示每一次截取的字符串
                      var start = p * provideNumber; // 开始截取的位置
                      var end = start + provideNumber; // 结束截取的位置
                      // 此处特殊处理最后一行的索引值
                      if (p == rowNumber - 1) {
                        // 最后一次不换行
                        tempStr = params.substring(start, paramsNameNumber);
                      } else {
                        // 每一次拼接字符串并换行
                        tempStr = params.substring(start, end) + "\n";
                      }
                      newParamsName += tempStr; // 最终拼成的字符串
                    }
                  } else {
                    // 将旧标签的值赋给新标签
                    newParamsName = params;
                  }
                  //将最终的字符串返回
                  return newParamsName;
                },
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#1A1A1A",
                },
              },
            },
            series: [
              {
                name: "实收金额",
                data: allMoney,
                ...seriesStyle,
                color: "#0072F6",
                label: {
                  show: true,
                  precision: 1,
                  position: "right",
                  textStyle: {
                    color: "#0072F6 ",
                  },
                },
              },
              {
                name: "销售金额",
                data: payMoney,
                ...seriesStyle,
                color: "#F6A200",
                label: {
                  show: true,
                  precision: 1,
                  position: "right",
                  textStyle: {
                    color: "#F6A200 ",
                  },
                },
              },
              {
                name: "退款金额",
                data: refundMoney,
                ...seriesStyle,
                color: "#FF5F57",
                label: {
                  show: true,
                  precision: 1,
                  position: "right",
                  textStyle: {
                    color: "#FF5F57 ",
                  },
                },
              },
            ],
          };
          classifyMoneyChart.setOption(option);
          // classifyMoneyChart.on("click", (param) => {
          //   if (statisticsData.value.goods_classes.length) {
          //     const item = statisticsData.value.goods_classes.find(
          //       (el) => el.poc_name === param.name
          //     );
          //     const lastValue = item.poc_id;
          //     emit("storeClick", lastValue);
          //   }
          // });
        }
      }
      const classifyNumberChartRef = ref(null);
      function classifyNumberEchart() {
        if (classifyNumberChartRef.value) {
          const classifyNumberChart = echarts.init(classifyNumberChartRef.value);
          window.addEventListener("resize", function () {
            classifyNumberChart.resize();
          });
          const xData = [];
          const payNumber = [];
          const refundNumber = [];
          const allNumber = [];
          const seriesStyle = {
            type: "bar",
            barWidth: 20,
            itemStyle: {
              borderRadius: [0, 8, 8, 0],
            },
          };

          statisticsData.value &&
            statisticsData.value.goods_classes &&
            statisticsData.value.goods_classes.length &&
            statisticsData.value.goods_classes.forEach((el) => {
              xData.push(el.poc_name);
              payNumber.push(el.order_no);
              refundNumber.push(
                el.orr_refund && el.orr_refund.refund_no
                  ? +el.orr_refund.refund_no
                  : 0
              );
              allNumber.push(
                (el.orr_refund && el.orr_refund.refund_no
                  ? +el.orr_refund.refund_no
                  : 0) + +el.order_no
              );
            });
          const option = {
            tooltip: {},
            legend: {
              itemWidth: 32,
              itemHeight: 14,
              itemGap: 30,
              textStyle: {
                color: "#666",
                fontSize: 14,
                align: "left",
              },
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "value",
              data: xData,
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "#ccc",
                },
              },
              axisTick: {
                show: false,
              },
            },
            yAxis: {
              type: "category",
              data: xData,
              axisTick: {
                show: false,
              },
              axisLabel: {
                color: "#1A1A1A",
                fontSize: "14",
                interval: 0,
                // align: "left",
                formatter: function (params) {
                  var newParamsName = ""; // 最终拼接成的字符串
                  var paramsNameNumber = params.length; // 实际标签的个数
                  var provideNumber = 12; // 每行能显示的字的个数
                  var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
                  /**
                   * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                   */
                  // 条件等同于rowNumber>1
                  if (paramsNameNumber > provideNumber) {
                    /** 循环每一行,p表示行 */
                    for (var p = 0; p < rowNumber; p++) {
                      var tempStr = ""; // 表示每一次截取的字符串
                      var start = p * provideNumber; // 开始截取的位置
                      var end = start + provideNumber; // 结束截取的位置
                      // 此处特殊处理最后一行的索引值
                      if (p == rowNumber - 1) {
                        // 最后一次不换行
                        tempStr = params.substring(start, paramsNameNumber);
                      } else {
                        // 每一次拼接字符串并换行
                        tempStr = params.substring(start, end) + "\n";
                      }
                      newParamsName += tempStr; // 最终拼成的字符串
                    }
                  } else {
                    // 将旧标签的值赋给新标签
                    newParamsName = params;
                  }
                  //将最终的字符串返回
                  return newParamsName;
                },
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#1A1A1A",
                },
              },
            },
            series: [
              {
                name: "订单总数",
                data: allNumber,
                ...seriesStyle,
                color: "#455FEF ",
                label: {
                  show: true,
                  precision: 1,
                  position: "right",
                  textStyle: {
                    color: "#455FEF ",
                  },
                },
              },
              {
                name: "销售笔数",
                data: payNumber,
                ...seriesStyle,
                color: "#60CBBA",
                label: {
                  show: true,
                  precision: 1,
                  position: "right",
                  textStyle: {
                    color: "#60CBBA ",
                  },
                },
              },
              {
                name: "退款笔数",
                data: refundNumber,
                ...seriesStyle,
                color: "#FF5F57",
                label: {
                  show: true,
                  precision: 1,
                  position: "right",
                  textStyle: {
                    color: "#FF5F57 ",
                  },
                },
              },
            ],
          };
          classifyNumberChart.setOption(option);
          // classifyNumberChart.on("click", (param) => {
          //   if (statisticsData.value.goods_classes.length) {
          //     const item = statisticsData.value.goods_classes.find(
          //       (el) => el.poc_name === param.name
          //     );
          //     const lastValue = item.poc_id;
          //     emit("storeClick", lastValue);
          //   }
          // });
        }
      }
      return {
        paywayMoneyChartRef,
        paywayNumberChartRef,
        statisticsData,
        roundNumber,
        storeMoneyChartRef,
        storeNumberChartRef,
        classifyNumberChartRef,
        classifyMoneyChartRef,
      };
    },
  };
</script>

<style lang="scss">
  .chart-container {
    // height: 56vh;
    // overflow-y: auto;
  }
</style>